import './bootstrap'

import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import focus from '@alpinejs/focus'
import mask from '@alpinejs/mask'
import initialiseAlpine from './alpine/init'

Alpine.plugin(focus)
Alpine.plugin(intersect)
Alpine.plugin(mask)

initialiseAlpine(Alpine)

window.Alpine = Alpine
Alpine.start()
Array.from(document.querySelectorAll('[x-cloak]')).forEach((e) => e.removeAttribute('x-cloak'))
